/* HomePage.css */
.home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
    color: black;
}

.section {
    width: 90%; /* Adjust based on your design preference */
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Simple shadow for depth */
    background-color: #f9f9f9; /* Light background for each section */
}

/* Optionally, style each section differently */
#section-one {
    background-color: #d1eaeb;
    
}

#section-two {
    background-color: #b2c3fd;

}

#section-three {
    background-color: #6e6ea0;
}
