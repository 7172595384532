

/* App-wide styles */
html, body, #root {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column; /* Allow for column layout */
    min-height: 100vh; /* Full viewport height */
    font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
    line-height: 1.5;
    font-weight: 400;
    color-scheme: light dark;
    color: rgba(255, 255, 255, 0.87);
    background-color: #242424;
}
    body > div {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

.header, .footer {
    flex-shrink: 0; /* Prevents header and footer from shrinking */
    width: 100%; /* Ensures full width */
    /* Add more styling here */
}

ul {
    list-style-type: none;
    padding: 0;
}
li {
    margin: 5px 0;
    padding: 10px;
    border-radius: 5px;
    text-align: left;
}


/*.body {
    flex: 1; /* Allows body content to expand */
    overflow-y: auto; /* Adds scroll to body content if it overflows */
    /* Add more styling here */
/*}*/

@media (prefers-color-scheme: light) {
    :root {
        color: #213547;
        background-color: #ffffff;
    }

    a:hover {
        color: #747bff;
    }

    button {
        background-color: #f9f9f9;
    }
}


.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #333;
    color: #fff;
    padding: 10px 20px;
}

    .header .logo {
        font-size: 24px;
        font-weight: bold;
    }

    .header nav {
        display: flex;
    }

        .header nav a {
            color: #fff;
            text-decoration: none;
            margin-left: 20px;
            padding: 5px 10px;
            border-radius: 5px;
            transition: background-color 0.3s;
        }

            .header nav a:hover {
                background-color: #555;
            }
.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #333;
    color: #fff;
    padding: 20px;
    position: relative;
    bottom: 0;
    margin-top: auto;
}
    .footer .copy {
        font-size: 14px;
    }

    .footer nav {
        display: flex;
    }

        .footer nav a {
            color: #fff;
            text-decoration: none;
            margin-left: 20px;
            padding: 5px 10px;
            border-radius: 5px;
            transition: background-color 0.3s;
        }

            .footer nav a:hover {
                background-color: #555;
            }

/*a {
    font-weight: 500;
    color: #646cff;
    text-decoration: inherit;
}

    a:hover {
        color: #535bf2;
    }*/

/*button {
    border-radius: 8px;
    border: 1px solid transparent;
    padding: 0.6em 1.2em;
    font-size: 1em;
    font-weight: 500;
    font-family: inherit;
    background-color: #1a1a1a;
    cursor: pointer;
    transition: border-color 0.25s;
    margin: 20px;
}

    button:hover {
        border-color: #646cff;
    }

    button:focus,
    button:focus-visible {
        outline: 4px auto -webkit-focus-ring-color;
    } */