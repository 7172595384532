#root {
  max-width: 98%;
  margin: 0 auto;
  padding: 0;
  text-align: center;
  min-height:100vh;
}

.app-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.logo {
  height: 6em;
  padding: 1.5em;
  will-change: filter;
  transition: filter 300ms;
}
.logo:hover {
  filter: drop-shadow(0 0 2em #646cffaa);
}
.logo.react:hover {
  filter: drop-shadow(0 0 2em #61dafbaa);
}

@keyframes logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (prefers-reduced-motion: no-preference) {
  a:nth-of-type(2) .logo {
    animation: logo-spin infinite 20s linear;
  }
}

.card {
  padding: 2em;
  flex: 1; /* Allow cards to expand and fill space */
}

.read-the-docs {
  color: #888;
}


.layout {
  display: flex;
  flex-direction: column;
  flex: 1; /* Ensures the layout takes full height */
}

.header {
  flex-shrink: 0; /* Prevent header from shrinking */
  background-color: #333; /* Header background color */
  color: white; /* Header text color */
  padding: 10px; /* Header padding */
}

.footer {
  flex-shrink: 0; /* Prevent footer from shrinking */
  background-color: #333; /* Footer background color */
  color: white; /* Footer text color */
  padding: 20px; /* Footer padding */
  text-align: center; /* Center footer content */
}